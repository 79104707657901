import React from "react";

import { Route, Routes } from "react-router-dom";

import Main from "app/screens/main";
import TermsOfUse from "app/screens/terms";
import PrivacyPolicy from "app/screens/privacy";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/terms-of-use" element={<TermsOfUse />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    </Routes>
  );
};

export default AppRoutes;

import React, { useState } from "react";

import Lottie from "react-lottie";
import CookieConsent from "react-cookie-consent";
import styled from "styled-components";
import { Turn as Hamburger } from "hamburger-react";
import { Header, Button, Grid, Image } from "semantic-ui-react";
import useDimensions from "react-use-dimensions";

import logo from "app/assets/images/logo.png";
import logoExpanded from "app/assets/images/logo-expanded.png";

import config from "app/config";

import background from "app/assets/images/background.png";
import plumber from "app/assets/images/plumber.png";
import appleDownload from "app/assets/images/apple-preferred-2x.png";
import googleDownload from "app/assets/images/google-play-badge.png";

export default function Main() {
  const [open, setOpen] = useState(false);
  const [ref, { x, y, width }] = useDimensions();

  return (
    <div
      ref={ref}
      style={{
        // height: "1000px",
        // background: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div
        style={{
          padding: 15,
          margin: 0,
        }}
      >
        <div style={{ padding: 0, marginBottom: 0 }}>
          <NavWrapper>
            {width > 500 ? <Logo src={logoExpanded} /> : <Logo src={logo} />}
          </NavWrapper>
        </div>
        <HeroWrapper>
          <Image src={plumber} size="medium" style={{ height: "300px" }} />
        </HeroWrapper>
        <HeroWrapper>
          <Header as="h2">Download the app</Header>
        </HeroWrapper>
        <ContentWrapper>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.open(
                "https://apps.apple.com/app/taski-local-help-made-easy/id6736351344"
              );
            }}
          >
            <Image
              src={appleDownload}
              style={{ width: "151px", height: "51px" }}
            />
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.open(
                "https://play.google.com/store/apps/details?id=ai.taski.app"
              );
            }}
          >
            <Image src={googleDownload} style={{ height: "75px" }} />
          </div>
        </ContentWrapper>
      </div>
    </div>
  );
}

const NavWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (hover: hover) and (pointer: fine) {
    a:hover::before {
      left: 0;
      right: auto;
      width: 100%;
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (hover: hover) and (pointer: fine) {
    a:hover::before {
      left: 0;
      right: auto;
      width: 100%;
    }
  }
`;

const HeroWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
`;

const Logo = styled.img`
  width: 56px;
  height: 56px;
`;

const LogoExpanded = styled.img`
  width: 214px;
  height: 60px;
`;
const MainNav = styled.div`
  display: flex;
  justify-content: space-between;

  ul {
    display: flex;
    margin: 0px;
    padding: 0px;
    align-items: center;

    li {
      list-style: none;
      margin-right: 20px;
    }
  }
`;

const ActionLink = styled.a`
  font-size: 17px;
  font-weight: 500;
  color: #8d8d8d;
  display: block;
  -webkit-transition: 0.5s;
  cursor: pointer;
  transition: 0.5s;
  position: relative;
  text-decoration: none;
  background-color: transparent;

  &:hover {
    color: black;
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 2px;
    background-color: red;
    transition: width 0.6s cubic-bezier(0.35, 1, 0.5, 1);
  }
`;

const MainContentWrapper = styled.div`
  padding: 40px;

  @media only screen and (max-width: 1000px) {
    padding: 10px;
  }
`;

const JoinWrapper = styled.div`
  display: flex;

  @media only screen and (max-width: 600px) {
    justify-content: flex-end;
  }
`;

const Content = styled.div`
  margin-bottom: 40px;

  .main-parents-text {
    font-weight: bold;
    font-size: 64px;
    line-height: 1.09em;
    color: #403f40;
    margin-bottom: 20px;
  }

  .main-parents-description {
    font-size: 30px;
    line-height: 1em;
    word-break: break-all;

    .students {
      margin: 0px 4px;
      color: #ea5252;
    }
    .teachers {
      color: #ea5252;
    }
  }

  @media only screen and (max-width: 1000px) {
    .main-parents-text {
      font-size: 46px;
    }
  }
`;

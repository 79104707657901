import React from "react";

import styled from "styled-components";

import logoExpanded from "app/assets/images/logo-expanded.png";

const PrivacyPolicy = () => {
  return (
    <div
      style={{
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div
        style={{
          padding: 15,
          margin: 0,
        }}
      >
        <div style={{ padding: 0, marginBottom: 0 }}>
          <NavWrapper>
            <Logo src={logoExpanded} />
          </NavWrapper>
        </div>
        <ContentWrapper>
          <h1 style={{ textAlign: "center" }}>
            ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ ДЛЯ МОБИЛЬНОГО ПРИЛОЖЕНИЯ –{" "}
            <a href="https://www.taski.uz/">www.taski.uz</a>
          </h1>

          <h2>1. ОПРЕДЕЛЕНИЕ ПОНЯТИЙ</h2>

          <p>
            <b>"Закон"</b> означает закон Республики Узбекистан "О персональных
            данных" (
            <a href="https://lex.uz/docs/4396428">
              "https://lex.uz/docs/4396428"
            </a>
            ) со всеми изменениями и дополнениями, а также иные законодательные
            акты Республики Узбекистан. <br />
            <b>«Мобильное приложение»</b> — это программное обеспечение (со
            всеми существующими дополнениями и улучшениями), предназначенное для
            работы на смартфонах, планшетах, часах и других мобильных
            устройствах, и разработанное для конкретной платформы (iOS, Android,
            Windows Phone и т. д.). Для целей настоящей Политики под Мобильным
            приложением подразумевается следующее программное обеспечение:
            <a href="https://www.taski.uz/">www.taski.uz</a>
            <br />
            <b>"Персональные данные"</b> означает совокупность личных данных
            и/или неперсонифицированной информации о Пользователе,
            предоставляемую им самим Правообладателю и/или автоматически
            собираемую Правообладателем и/или третьими лицами.
            <br />
            <b>"Политика"</b> означает настоящую Политику конфиденциальности
            мобильного приложения (со всеми существующими дополнениями и
            изменениями).
            <br />
            <b>"Пользователь"</b> означает юридическое или физическое лицо,
            которое загрузило Мобильное приложение на смартфон, планшет, часы
            или любое другое мобильное устройство и/или осуществило активацию
            такого Мобильного приложения на одном из указанных устройств.
            <br />
            <b>"Пользовательское соглашение"</b> означает соглашение,
            заключаемое между Правообладателем и Пользователем в отношении
            порядка, правил и особенностей использования Пользователем
            Мобильного приложения. Пользователь присоединяется к такому
            соглашению и не имеет права вносить и/или требовать внесения в него
            каких-либо изменений или дополнений. Пользователь может ознакомиться
            с условиями Пользовательского соглашения по следующей ссылке:
            <a href="https://www.taski.uz/">www.taski.uz</a>
            <br />
            <b>"Правообладатель"</b>означает следующее лицо, которому
            принадлежат исключительные права владения Мобильным приложением:
            <a href="https://www.taski.uz/">www.taski.uz</a>
            <br />
            <b>"Файлы куки"</b> означает небольшие файлы, отправляемые
            каким-либо мобильным приложениям или сайтом, и размещаемые на
            смартфонах, планшетах, часах и других мобильных устройствах
            Пользователя, для улучшения работы таких приложений или сайтов, а
            также качества размещенного в них контента.
            <br />
          </p>

          <h2>2. ОТНОШЕНИЯ, НА КОТОРЫЕ РАСПРОСТРАНЯЕТСЯ ПОЛИТИКА</h2>

          <h3>Общие положения</h3>
          <p>
            Данная Политика используется и применима исключительно к
            Персональным данным, получаемым от Пользователя в связи с
            использованием им Мобильного приложения. Положения данной Политики
            направлены на:
            <br />
            <b>(1)</b> определение видов и типов получаемых Персональных данных,
            направлений и целей использования (обработки) Персональных данных, а
            также источников получения таких Персональных данных; и<br />
            <b>(2)</b> определение прав Пользователя в отношении защиты
            конфиденциальности передаваемых им Персональных данных; и<br />
            <b>(3)</b> определение лиц, ответственных за обработку и хранение
            Персональных данных, а также третьих лиц, которым такие данные
            раскрываются (полностью или частично).
            <br />
            Правила настоящей Политики не применяются в случае обработки
            третьими лицами Персональных данных, которые добровольно
            предоставляются Пользователем.
            <br />
            Посредством установки и/или активации Мобильного приложения на
            смартфоне, планшете, часах или другом мобильном устройстве
            Пользователь соглашается с условиями данной Политики и дает свое
            согласие Правообладателю на сбор, обработку, удержание и хранение
            Персональных данных в порядке и на условиях, предусмотренных
            настоящей Политикой.
            <br />
            Если Пользователь не согласен с условиями Политики и/или отдельные
            условия Политики ему не понятны, в таком случае Пользователь обязан
            немедленно прекратить использование Мобильного приложения.
            <br />
          </p>

          <h3>Права пользователя по защите персональных данных</h3>
          <p>
            В связи с предоставлением Персональных данных Пользователь
            автоматически получает следующие права:
            <br />
            <b>(1)</b> получать данные, касающиеся их обработки (основания и
            цели такой обработки, применяемые способы обработки, сведения о
            лицах, которые имеют доступ к ним или которым они могут быть
            раскрыты на основании договора или Закона).
            <br />
            <b>(2)</b> получать данные о месте нахождения и идентификационных
            данных лиц, совершающих обработку Персональных данных.
            <br />
            <b>(3)</b> получать данные о сроках хранения Персональных данных.
            <br />
            <b>(4)</b> получать данные об осуществленной или о предполагаемой
            трансграничной передаче Персональных данных.
            <br />
            <b>(5)</b> получать информацию о месте нахождения и
            идентификационных данных лиц, совершающих хранение Персональных
            данных.
            <br />
            <b>(6)</b> обжаловать действия или бездействие Правообладателя в
            уполномоченный орган по защите прав субъектов персональных данных
            или в судебном порядке.
            <br />
            <b>(7)</b> получать возмещение убытков и/или компенсацию морального
            вреда в судебном порядке в следствие допущенных Правообладателем
            и/или третьими лицами нарушений прав Пользователя на охрану и защиту
            его Персональных данных.
            <br />
            <b>(8)</b> реализовывать иные права в области защиты персональных
            данных, предусмотренные Законом или положениями данной Политики.
            <br />
          </p>

          <h2>3. ПЕРЕЧЕНЬ СОБИРАЕМЫХ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>

          <h3>Неперсонифицированная информация о пользователях</h3>
          <p>
            В связи с использованием Мобильного приложения Правообладатель может
            автоматически собирать и обрабатывать следующую
            неперсонифицированную информацию о Пользователе:
            <br />
            <b>(1)</b> информацию о трафике, возможном количестве совершенных
            кликов, логи и другие данные.
            <br />
            <b>(2)</b> информацию о месте нахождении Пользователя (геолокация).
            Пользователь может в любой момент отключить геолокацию путем
            изменения настроек устройства, с которого осуществлен вход в
            Мобильное приложение. Геолокация используется Мобильным приложением
            только тогда, когда Пользователь активно использует такое
            приложение. При выходе из Мобильного приложения геолокация перестает
            функционировать.
            <br />
            <b>(3)</b> информацию об устройстве (идентификационный номер, сеть
            мобильного оператора), с которого выполняется вход, операционная
            система, платформа, тип браузера и другая информация о браузере, IP
            адрес.
            <br />
          </p>

          <h3>Личные данные о пользователях</h3>
          <p>
            Пользователь предоставляет Правообладателю о себе следующие личные
            данные:
            <br />
            <b>(1)</b> полные фамилию, имя и отчество.
            <br />
            <b>(2)</b> адрес места постоянного проживания.
            <br />
            <b>(3)</b> адрес электронной почты.
            <br />
            <b>(4)</b> номер мобильного телефона.
            <br />
            <b>(5)</b> данные, которые содержатся в личном кабинете (профиле)
            Пользователя, все внутренние переписки Пользователя (если есть), а
            также иная активность личного кабинета (профиля) Пользователя.
            <br />
            <b>(6)</b> данные о совершенных Пользователям заказах/покупках и/или
            полученных/оплаченных услугах через Мобильное приложение.
            <br />
            <b>(7)</b> данные о всех совершенных Пользователем публикациях в
            Мобильном приложении, включая, однако не ограничиваясь, комментарии,
            выставление оценок, отзывы, публикация отчетов, видео и фотографии,
            проставление лайков, рейтинги и/или какие-либо другие формы
            активности, доступные Пользователю в Мобильном приложении, и/или
            создаваемом контенте.
            <br />
            <b>(8)</b> данные и информация, получаемая в результате объединения
            тех или иных Персональных данных конкретного Пользователя, а также
            данные и информация, получаемые данные о Пользователе, получаемые от
            третьих лиц (партнеров, маркетологов, исследователей).
            <br />
            Пользователь является единственным ответственным лицом за полноту
            предоставляемых личных (персональных) данных и обязан осуществлять
            своевременное их изменение (обновление, проверку, корректировку) на
            регулярной основе.
            <br />
            Правообладатель исходит из того, что все предоставленные
            Пользователем личные (персональные) данные являются достоверными, и
            что Пользователь поддерживает такую информацию в актуальном
            состоянии.
            <br />
          </p>

          <h3>Использование Captcha</h3>
          <p>
            В Мобильном приложение интегрирована Captcha, которая представляет
            собой разновидность Файлов куки, целью которых в данном случае
            является
            <b>(1)</b> защита Пользователя от возможного спама, поступающего от
            третьих лиц в сети Интернет, а также от иного нерелевантного и/или
            запрещенного контента, и <b>(2)</b> идентификация Пользователя с
            целью его отличия от ботов/роботов, и <b>(3)</b> улучшение
            возможностей Пользователя в пользовании контентом Мобильного
            приложения.
            <br />
            Вход на сайт через Мобильное приложение позволяет Captcha
            автоматически получать доступ и производить сбор следующей
            информации:
            <br />
            <b>(1)</b> Файлы куки, установленные в браузере в течение последних
            6 (шести) месяцев; и/или
            <br />
            <b>(2)</b> количество прикосновений к сенсорному экрану устройства,
            совершенное Пользователем (количество совершенных кликов); и/или
            <br />
            <b>(3)</b> информация о стилизации веб-страницы; и/или
            <br />
            <b>(4)</b> языковые настройки браузера; и/или
            <br />
            <b>(5)</b> плагины, установленные в браузере Пользователя; и/или
            <br />
            <b>(6)</b> все объекты Javascript.
            <br />
          </p>

          <h3>Информация о совершаемых транзакциях</h3>
          <p>
            Пользователь через Мобильное приложение может осуществлять оплату за
            товары или услуги, посредством введения в специальное поле
            информации о платежной карте и идентификационных данных собственника
            такой карты. Пользователь может совершать оплату в Мобильном
            приложении следующим способом:
            <ul>
              <li>посредством банковской карты.</li>
              <li>с помощью платежной системы Pay Pal.</li>
              <li>с помощью платежной системы Яндекс.Деньги.</li>
              <li>с помощью платежной системы Apple Pay.</li>
              <li>с помощью платежной системы Google Pay.</li>
              <li>
                а также следующим способом: любой способ электронного платежа.
              </li>
            </ul>
            Сбор и обработка данных о Пользователе в данном случае
            осуществляется исключительно для целей проведения оплаты,
            недопущения мошенничества, а также соблюдения иных требований
            законодательства. Пользователь дает свое согласие на доступ и сбор
            Правообладателем и соответствующей платежной системой или банковским
            учреждением, через которую/которое проводится оплата, к таким
            Персональным данным, а также соглашается с политикой
            конфиденциальности соответствующей платежной системы или банковского
            учреждения.
          </p>

          <h3>Использование файлов куки</h3>
          <p>
            Данное Мобильное приложение применяет определенные Файлы куки для
            сохранения IP-адреса, предпочтений Пользователей или типа
            используемого устройства с целью <b>(1)</b> ведения статистики
            посещений и трафика сайта, и <b>(2)</b> персонализации выводимых на
            экран Пользователя данных, и <b>(3)</b> сохранения данных,
            необходимых для идентификации Пользователя, в том числе при доступе
            с разных устройств, и <b>(4)</b> показа рекламы в соответствии с
            интересами и предпочтениями Пользователя. Мобильное приложение может
            использовать как собственные Файлы куки, принадлежащие
            Правообладателю, так и Файлы куки третьих лиц.
            <br />
            Мобильное приложение использует следующие Файлы куки:
            <br />
            <b>(1)</b> Технические (функциональные) Файлы куки, которые нужны
            для контроля трафика и передачи данных, для идентификации
            Пользователей и предоставления доступа Пользователю к контенту
            Мобильного приложения и без которых использование Мобильного
            приложения является функционально ограниченным, а также для
            предотвращения предоставления рекомендаций, не соответствующих
            интересам Пользователя.
            <br />
            <b>(2)</b> Статистические Файлы куки, которые нужны для отслеживания
            частоты посещаемости сайта Пользователями, для выявления способов
            использования Пользователем Мобильного приложения, а также для
            выявления типа и вида контента, который является популярным или
            интересным для Пользователя.
            <br />
            <b>(3)</b> Геолокационные Файлы куки, которые нужны для определения
            места нахождения Пользователя для персонализации выводимого на экран
            его устройства контента в Мобильном приложении.
            <br />
            <b>(4)</b> Рекламные (маркетинговые) Файлы куки, которые нужные для
            размещения рекламных и/или маркетинговых объявлений в Мобильном
            приложении, которые соответствуют предпочтениям и интересам
            Пользователя.
            <br />
            <b>(5)</b> Файлы куки третьих лиц, которые устанавливаются третьими
            лицами с разрешения Пользователя и предназначены для проведения
            статистических исследований, касающихся поведения Пользователя в
            сети Интернет и/или направления персонализированных рекламных или
            маркетинговых материалов Пользователю и/или предоставления товаров
            или услуг.
            <br />
            Пользователь имеет право в любой момент отключить Файлы куки в
            Мобильном приложении путем изменения определенных настроек в своем
            смартфоне, планшете, часах или другом мобильном устройстве. Такое
            отключение может повлечь за собой ограничение или изменение доступа
            Пользователя к функциональным возможностям Мобильного приложения
            и/или контенту. Для отключения Файлов куки нужно выполнить
            соответствующие действия с использованием настроек в мобильном
            устройстве.
          </p>

          <h2>4. ЦЕЛИ СБОРА И ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>

          <h3>Определение целей обработки</h3>
          <p>
            Сбор и обработка Персональных данных осуществляется в следующих
            целях:
            <br />
            <b>(1)</b> для анализа поведения Пользователя, а также выявления
            предпочтений Пользователя к определенному виду контента.
            <br />
            <b>(2)</b> для оперативной и корректной работы Мобильного
            приложения, улучшения функционирования работы Мобильного приложения,
            улучшения контента Мобильного приложения, улучшения внутренней
            архитектуры и функциональности Мобильного приложения.
            <br />
            <b>(3)</b> для идентификации Пользователя.
            <br />
            <b>(4)</b> для предоставления персонализированных рекламных и
            маркетинговых материалов.
            <br />
            <b>(5)</b> для направления персонализированных рекламных и
            маркетинговых материалов на указанный электронный адрес и/или
            мобильный телефон Пользователя.
            <br />
            <b>(6)</b> для соблюдения требований Закона.
            <br />
            <b>(7)</b> для отслеживания заказов/покупок, совершенных
            Пользователем через Мобильное приложение.
            <br />
            <b>(8)</b> для определения места нахождения Пользователя.
            <br />
            <b>(9)</b> для технической поддержки Мобильного приложения,
            выявления проблем в его работе и их устранение.
            <br />
            <b>(10)</b> для поддержания связи с Пользователем (коммуникация).
            <br />
            <b>(11)</b> В целях продажи специалистом либо приобретения
            пользователем услуг специалиста
            <br />
            <b>(12)</b> для выполнения иных обязательств Правообладателя,
            которые возникли перед Пользователем.
            <br />
            <b>(13)</b> для проведения статистических исследований.
            <br />
            <b>(14)</b> для любых других целей, при условии получения отдельного
            согласия от Пользователя.
            <br />
            Обработка Персональных данных осуществляется на основе принципов:{" "}
            <b>(1)</b> законности целей и способов обработки; и <b>(2)</b>{" "}
            добросовестности; и <b>(3)</b> соответствия целей обработки
            Персональных данных целям, заранее определенным и заявленным при
            сборе таких Персональных данных; и <b>(4)</b> соответствия объема и
            характера обрабатываемых Персональных данных заявленным целям их
            обработки.
            <br />
          </p>

          <h3>Условия обработки персональных данных</h3>
          <p>
            Обработка Персональных данных проводится в случаях: <b>(1)</b>{" "}
            получения согласия от Пользователя; или <b>(2)</b> достижения
            Правообладателем целей, предусмотренных международным договором или
            Законом; или <b>(3)</b> предоставления Пользователем своих
            Персональных данных неограниченному кругу лицу; или <b>(4)</b>{" "}
            выполнения иных обязательств Правообладателя перед Пользователем,
            включая, однако не ограничиваясь, предоставление определенного
            контента Пользователю; или <b>(5)</b> спасения жизни или здоровья
            Пользователя, когда согласие на обработку его Персональных данных не
            удается получить заблаговременно. В случае обезличивания
            Персональных данных, что не позволяет прямо или опосредованно
            определить Пользователя, последующее использование и раскрытие таких
            данных третьим лицам допускается и в отношении их более не
            применяются правила данной Политики. Правообладатель принимает все
            возможные меры для защиты конфиденциальности полученных Персональных
            данных, за исключением случаев, когда Пользователь сделал такие
            данные общедоступными. Обработка Персональных данных осуществляется
            с использованием средств автоматизации и без использования таких
            средств автоматизации.
          </p>

          <h2>5. ДОСТУП ТРЕТЬИХ ЛИЦ К ПЕРСОНАЛЬНЫМ ДАННЫМ</h2>

          <h3>Использование ремаркетинговых сервисов</h3>
          <p>
            Правообладатель использует ремаркетинг для рекламы Пользователю
            контента Мобильного приложения на других сайтах, посещаемых
            Пользователем.
            <br />
            Ремаркетинговые сервисы Правообладателю предоставляются посредством
            платформы Telegram. Telegram собирает и обрабатывает
            неперсонифицированные данные, которые напрямую не позволяют
            установить или идентифицировать Пользователя. Собираемая информация,
            как правило, может включать <b>(1)</b> контент, который просматривал
            Пользователь, <b>(2)</b> дату и время, когда Пользователь
            просматривал контент, <b>(3)</b> данные о геолокации. Сбор и
            обработка такой неперсонифицированной информации позволяет
            предоставлять Пользователю более таргетинговый рекламный или
            маркетинговый контент.
            <br />
            Посредством установки Мобильного приложения Пользователь соглашается
            с политикой конфиденциальности (Privacy Policy) для Telegram, а
            также с автоматической установкой на устройство Пользователя
            соответствующих Файлов куки.
            <br />
            Пользователь имеет право в любой момент отказаться от такой рекламы
            посредством изменений соответствующих настроек браузера и
            устройства, с которого совершается вход в Мобильное приложение.
            <br />
          </p>

          <h3>Использование аналитических платформ</h3>
          <p>
            Правообладатель использует аналитическую платформу Google Analytics
            для <b>(1)</b> отслеживания частоты посещаемости сайта
            Пользователями; и <b>(2)</b> отслеживания способов использования
            Пользователем Мобильного приложения и/или его контента; и <b>(3)</b>{" "}
            выявления типа и вида контента, который является популярными среди
            Пользователей; и <b>(4)</b> определения места нахождения
            Пользователя. Пользователь также дает свое согласие Правообладателю
            на использование им информации, полученной о Пользователе от Google
            Analytics.
            <br />
            Для указанных целей аналитическая платформа Google Analytics может
            собирать данные об IP адресе, геолокации, поведении Пользователя, а
            также его предпочтениях и интересе в отношении определенного
            контента.
            <br />
            Аналитическая платформа Google Analytics получает доступ к
            Персональным данным с тем, чтоб предоставить Правообладателю
            понимание того насколько эффективно работает его Мобильное
            приложение, какой именно контент является популярным, насколько
            эффективно размещение в нем той или иной рекламы, а также для целей
            разработки и/или улучшения существующей маркетинговой стратегии
            Правообладателя.
            <br />
            Посредством установки Мобильного приложения Пользователь соглашается
            с политикой конфиденциальности (Privacy Policy) Google Analytics, а
            также с автоматической установкой на устройство Пользователя
            соответствующих Файлов куки.
            <br />
          </p>

          <h3>Раскрытие персональных данных третьим лицам</h3>
          <p>
            Правообладатель имеет право раскрывать Персональные данные{" "}
            <b>(1)</b> своим аффилированным лицам, филиалам и
            представительствам, открытым как на территории Республики
            Узбекистан, так и на территории других государств;
            <b>(2)</b>правопреемникам Правообладателя, которые возникли в
            результате его ликвидации, реорганизации или банкротства, и которые
            получили исключительные права владения Мобильным приложением;
            <b>(3)</b> поставщикам платежных услуг или банковским (финансовым)
            учреждениям, для проведения транзакций Пользователя через Мобильное
            приложение; <b>(4)</b> третьим лицам исключительно для целей
            оказания получения Пользователем определенного контента или доступа
            к нему; <b>(5)</b> третьим лицам, когда Пользователем было дано
            согласие на раскрытие, передачу или обработку своих Персональных
            данных, а также в иных случаях, прямо предусмотренных
            законодательством или данной Политикой.
            <br />
            Правообладатель раскрывает Персональные данные только в том случае,
            если <b>(1)</b> уверен, что третьи лица будут соблюдать условия
            данной Политики и предпринимать такие же меры по защите
            конфиденциальности Персональных данных, которые предпринимает сам
            Правообладатель, и (2) согласие на такое раскрытие было
            предварительно выражено Пользователем и/или допускается на основании
            законодательства.
            <br />
          </p>

          <h3>Реклама от третьих лиц</h3>
          <p>
            Контент Мобильного приложения может содержать рекламные баннеры
            и/или ссылки на сайты третьих лиц. Использование Пользователем таких
            сайтов (путем перехода по ссылке или любым другим способом) может
            повлечь за собой сбор, обработку и использование Персональных
            данных, а также возможную автоматическую передачу Файлов куки на
            устройство Пользователя, с которого совершается переход на сайт
            третьих лиц. Правообладатель не несет какой-либо ответственности за
            способы, методы и порядок обработки Персональных данных сайтами
            третьих лиц. В следствие чего Правообладатель также не является
            ответственным лицом в случае раскрытия Персональных данных
            неограниченному кругу лиц в связи с использованием Пользователем
            таких сайтов. Правообладатель настоятельно рекомендует каждому
            Пользователю детально ознакомиться с политиками защиты персональных
            данных используемых сайтов. Пользователь имеет право в любой момент
            отключить такие рекламные баннеры и/или ссылки посредством
            выполнения соответствующих действий с использованием настроек.
            <br />
          </p>
          <br />

          <h2>6. РАЗМЕЩЕНИЕ РЕКЛАМЫ</h2>

          <h3>Реклама в мобильном приложении</h3>
          <p>
            Правообладатель вместе с контентом размещает в Мобильном приложении
            различные рекламные и маркетинговые материалы с учетом выявленных
            предпочтений Пользователя к тому или иному контенту. Размещение
            рекламы в Мобильном приложении предполагает установку на устройстве
            Правообладателя определенных Файлов куки.
            <br />
            Пользователь имеет право в любой момент отказаться от такой рекламы
            посредством выполнения соответствующих действий с использованием
            настроек.
            <br />
          </p>

          <h3>Рассылка рекламных материалов</h3>
          <p>
            Пользователь автоматически с установкой Мобильного приложения на
            устройство соглашается с правом Правообладателя отправлять на
            предоставленный электронный адрес и/или мобильный телефон
            персонализированные рекламные и маркетинговые материалы.
            <br />
            Пользователь имеет право в любой момент отказаться от получения
            таких рекламных и маркетинговых материалов посредством выполнения
            соответствующих действий с использованием настроек.
            <br />
            Обращаем внимание, что в случае отказа от получения рекламных и
            маркетинговых материалов, Пользователь может продолжать получать от
            Правообладателя какие-либо уведомления, связанные с порядком
            использования Мобильного приложения и/или его контента.
            <br />
          </p>

          <h2>7. НАПРАВЛЕНИЕ ЖАЛОБ И ЗАПРОСОВ ПРАВООБЛАДАТЕЛЮ</h2>

          <h3>Требование о прекращении обработки персональных данных</h3>
          <p>
            Каждый Пользователь имеет право выразить свое возражение
            Правообладателю против обработки и/или хранения его Персональных
            данных. Такое возражение может быть выражено с использованием
            настроек Мобильного приложения.
            <br />
          </p>

          <h3>Запрос на получение информации о персональных данных</h3>
          <p>
            Если у Пользователя возникают вопросы, связанные с порядком
            применения или использования настоящий Политики, порядком и/или
            способом обработки Персональных данных, Пользователь может задать
            такой вопрос Правообладателю письмом на электронную почту (e-mail).
            <br />
            <b>
              Изменение (обновление, дополнение, корректировка) или удалении
              персональных данных
            </b>
            <br />
            Пользователь имеет право в любой момент самостоятельно изменить или
            удалить свои Персональные данные, за исключением случаев, когда
            такое изменение или удаление может привести
            <b>(1)</b> к нарушению правил настоящей Политики; или <b>(2)</b> к
            нарушению законодательства; <b>(3)</b> характер таких Персональных
            данных является доказательством в каком-либо судебном процессе,
            возникшем между Правообладателем и Пользователем. Для этого
            Пользователю требуется удалить свой личный аккаунт (профиль) в
            Мобильном приложении.
            <br />
            Правообладатель имеет право в любой момент удалить личный
            аккаунт/профиль Пользователя, а также все Персональные данные о
            Пользователе, если он нарушил условия настоящей Политики и/или
            Пользовательского соглашения..
            <br />
            В случае удаления Персональных данных о Пользователе,
            автоматическому удалению подлежат также все совершенные таким
            Пользователем публикации (комментарии, выставление оценок, отзывы,
            публикация отчетов, видео и фотографии, проставление лайков,
            рейтинги) и/или какие-либо другие формы активности, доступные
            Пользователю в Мобильном приложении..
            <br />
          </p>

          <h2>8. СРОКИ И ПОРЯДОК ХРАНЕНИЯ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
          <p>
            Хранение осуществляется третьими лицами по поручению
            Правообладателя. Пользователь дает свое согласие на хранение его
            Персональных данных третьими лицами по поручению Правообладателя,
            при условии сохранения такими третьими лицами конфиденциальности
            полученных Персональных данных. Функции хранения Информации поручены
            следующему лицу: APP STORE, GOOGLE PLAY (далее <b>"Хранитель"</b>).
            Поскольку лицо, осуществляющее хранение Персональных данных,
            находится за пределами Республики Узбекистан и Европейского
            союза/Европейской Экономической Зоны, Пользователь настоящим дает
            свое согласие на трансграничную передачу своих Персональных данных и
            на их хранение за пределами Республики Узбекистан. Пользователь
            настоящим считается уведомленным, что страны, на территорию которых
            осуществляется трансграничная передача Персональных данных, могут
            иметь недостаточный уровень защиты конфиденциальности Персональных
            данных, однако Правообладатель предпринимает все необходимые меры
            защиты по недопущению разглашения таких данных. Хранение
            осуществляется в течение всего срока использования Пользователем
            данного Мобильного приложения.
            <br />
            Правообладатель обязуется немедленно после прекращения использования
            Пользователем Мобильного приложения уничтожить или же обезличить его
            Персональные данные.
            <br />
          </p>

          <h2>9. ДОСТУП НЕСОВЕРШЕННОЛЕТНИХ К МОБИЛЬНОМУ ПРИЛОЖЕНИЮ</h2>
          <p>
            Использование Мобильного приложения предназначено для лиц старше 18
            лет, которые получают к нему доступ только при условии
            предоставления предварительного согласия на обработку их
            Персональных данных. Правообладатель проверяет возраст Пользователя
            путём получения и изучения паспортных данных Пользователя.
            <br />
            Если Пользователь является несовершеннолетним лицом, в таком случае
            он должен немедленно прекратить использование данного Мобильного
            приложения.
            <br />
          </p>

          <h2>10. ПОРЯДОК ЗАЩИТЫ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
          <p>
            Защита конфиденциальности Персональных данных является
            первостепенной и важной задачей для Правообладателя. Правообладатель
            придерживается всех требуемых международных стандартов, правил и
            рекомендаций по защите Персональных данных.
            <br />
            Правообладатель внедрил ряд технических и организационных методов,
            направленных на защиту Персональных данных от разглашения или
            несанкционированного доступа к ним третьих лиц. Для обеспечения
            сохранности и конфиденциальности получаемых Персональных данных
            Правообладатель использует следующие средства защиты:
            <br />
            <b>(1)</b> SSL (Security Sockets Layer) протокол.
            <br />
            <b>(2)</b> SET (Secure Electronic Transaction) протокол.
            <br />
            <b>(3)</b> автоматическое сохранение данных.
            <br />
            <b>(4)</b> Firewalls.
            <br />
            <b>(5)</b> а также:
            <br />
            установка пароля
            <br />   шифрование данных
            <br />
          </p>

          <h2>11. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h2>

          <h3>Доступность текста политики для ознакомления</h3>
          <p>
            Пользователи могут ознакомиться с условиями настоящей Политики по
            следующей ссылке:<a href="https://www.taski.uz/">www.taski.uz</a>
            Настоящая редакция Политики действует от 1 января 2024 годa.
            <br />
          </p>

          <h3>Изменение и дополнение Политики</h3>
          <p>
            Настоящая Политика может быть изменена время от времени.
            Правообладатель не несет какой-либо ответственности перед
            Пользователем за изменение условий настоящей Политики без разрешения
            и/или согласия Пользователя.
            <br />
            Пользователь сам обязуется на регулярной основе проверять положения
            настоящей Политики на предмет ее возможного изменения или
            дополнения.
            <br />
          </p>

          <h3>Применимое законодательство</h3>
          <p>
            Настоящая Политика разработана в соответствие с действующим
            законодательством о защите персональных данных Республики
            Узбекистан, в частности, с нормами закона Республики Узбекистан "О
            персональных данных" (
            <a href="https://lex.uz/docs/4396428">
              "https://lex.uz/docs/4396428"
            </a>
            ) со всеми изменениями и дополнениями, а также иными
            законодательными актами Республики Узбекистан.
            <br />
          </p>

          <h3>Риск разглашения</h3>
          <p>
            Вне зависимости от предпринимаемых Правообладателем мер защиты
            конфиденциальности получаемых персональных данных, Пользователь
            настоящим считается, должным образом, ознакомленным с тем, что любая
            передача Персональных данных в сети Интернет не может быть
            гарантировано безопасной, а потому Пользователь осуществляет такую
            передачу на свой собственный страх и риск.
            <br />
          </p>

          <h3>Публичная информация</h3>
          <p>
            В рамках Мобильного приложения Пользователь имеет право осуществлять
            публикации и размещать любой контент по своему собственному
            усмотрению и в любой из доступных форм (фото, видео, комментарий,
            статья, оценка, блог и т.п.). Такие публикации и контент являются
            общедоступными для других пользователей Мобильного приложения, в
            связи с чем Правообладатель не берет на себя какие-либо
            обязательства по защите Персональных данных, которые могут быть
            обнародованы или опубликованы в рамках такой публикации и/или
            контента.
          </p>
        </ContentWrapper>
      </div>
    </div>
  );
};

export default PrivacyPolicy;

const NavWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (hover: hover) and (pointer: fine) {
    a:hover::before {
      left: 0;
      right: auto;
      width: 100%;
    }
  }
`;

const Logo = styled.img`
  width: 56px;
  height: 56px;
`;

const ContentWrapper = styled.div`
  @media only screen and (min-width: 1000px) {
    padding: 4% 20% 0px 20%;
  }
  @media only screen and (max-width: 500px) {
    padding: 4% 0% 0px 0%;
  }
`;

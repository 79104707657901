import React from "react";

import styled from "styled-components";

import logoExpanded from "app/assets/images/logo-expanded.png";

const TermsOfUse = () => {
  return (
    <div
      style={{
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div
        style={{
          padding: 15,
          margin: 0,
        }}
      >
        <div style={{ padding: 0, marginBottom: 0 }}>
          <NavWrapper>
            <Logo src={logoExpanded} />
          </NavWrapper>
        </div>

        <ContentWrapper>
          <h1 style={{ textAlign: "center" }}>
            ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ ДЛЯ ПРИЛОЖЕНИЯ –{" "}
            <a href="https://www.taski.uz/">www.taski.uz</a>
          </h1>

          <h2>1. ОПРЕДЕЛЕНИЕ ПОНЯТИЙ</h2>
          <p>
            <b>"Компания", "Мы", "Наш", "Нас", "Нами"</b> или какие-либо другие
            схожие производные (в зависимости от контекста) означает следующее
            лицо:<a href="https://www.taski.uz/">www.taski.uz</a>, адрес
            местонахождения: San Francisco и Ташкент (включая, его филиалы и
            представительства как на территории Республики Узбекистан, так и за
            ее пределами, а также какие-либо другие лица, созданные в результате
            реорганизации Компании), которые являются владельцами Приложения или
            им управляют.
            <br />
            <b>"Контент приложения"</b> означает все объекты, размещенные
            Компанией и/или третьими лицами (с разрешения Компании) в
            Приложении, в том числе элементы дизайна, текст, графические
            изображения, иллюстрации, виртуальные объекты, видео, программы,
            музыка, звуки, информация, уведомления и какие-либо другие схожие
            объекты, их подборки или комбинации.
            <br />
            <b>"Обновления"</b> означает программный патч или программный пакет
            для Приложения, который время от времени выпускается Компанией,
            предлагается для бесплатной загрузки Пользователями, которые уже
            используют Приложение, и направлен на фиксацию неработающих функций
            Приложения, устранение багов (ошибок) в работе Приложения или
            внедрение небольших программных компонентов для обеспечения большей
            безопасности и совместимости Приложения с устройствами.
            <br />
            <b>"Платформа"</b> означает облачную платформу, на которой Компания
            разместила Приложение для его последующего скачивания Пользователем.
            Настоящее Приложение доступно для скачивания из App Store и Google
            Play.
            <br />
            <b>"ПО приложения"</b> означает программное обеспечение,
            разработанное Компанией (и/или третьими лицами по поручению
            Компании) для Приложения, включая, однако не ограничиваясь, весь
            софт, скрипты, коды (HTML коды), программы и т.п.
            <br />
            <b>"Пользователь", "Вы", "Ваш", "Вас", "Вами"</b> или какие-либо
            другие схожие производные (в зависимости от контекста) означает
            лицо, которое <b>(1)</b> пользуется Приложением и получило доступ к
            Услугам; и <b>(2)</b> дало свое согласие на соблюдение правил
            пользования Приложением, изложенных в тексте данного Соглашения,
            путем проставления специальной галочки при открытии Приложения.
            <br />
            <b>"Пользовательский контент"</b> означает <b>(1)</b> все
            публикации, совершенные Пользователем в Приложении, включая, однако
            не ограничиваясь, комментарии; оценки; отзывы; отчеты; фидбеки;
            размещенные видео, фотографии, музыка и другие медиа файлы;
            проставленные лайки; рейтинги и/или какие-либо другие формы
            активности, доступные Пользователю, а также <b>(2)</b> какой-либо
            другой контент, создаваемый Пользователем.
            <br />
            <b>"Приложение"</b> означает следующее приложение:{" "}
            <a href="https://www.taski.uz/">www.taski.uz</a>, которое
            Пользователь загружает через Платформу на смартфон или другое
            устройство.
            <br />
            <b>"Услуги"</b> означает совокупно Контент приложения и ПО
            приложения.
            <br />
            <b>"In-app покупка"</b> означает получение Пользователем за плату
            дополнительных возможностей и/или функционала для Приложения и/или
            приобретение каких-либо виртуальных товаров/услуг в рамках
            Приложения.
            <br />
          </p>

          <h2>2. ПРИСОЕДИНЕНИЕ К СОГЛАШЕНИЮ</h2>

          <h3>Общие положения</h3>
          <p>
            2.1. Настоящее Пользовательское соглашение (далее "Соглашение")
            определяет правила и порядок использования Приложения, права и
            обязанности Пользователей, а также регулирует поведение
            Пользователей при получении доступа к Приложению и Услугам.
            <br />
            2.2. Пользователь принимает условия данного Соглашения путем
            проставления специальной галочки в отведенном поле при загрузке
            Приложения на смартфон или другое устройство.
            <br />
            2.3. Данное Соглашение является обязательным для его сторон (т.е.
            для Компании и Пользователя). Уступка Пользователем своих прав по
            данному Соглашению возможна только после получения предварительного
            письменного согласия от Компании.
            <br />
            2.4. Если Вы являетесь законным представителем (родителем, опекуном)
            несовершеннолетнего Пользователя, в таком случае Вы автоматически
            соглашаетесь от своего имени и от имени такого несовершеннолетнего
            Пользователя на условия данного Соглашения.
            <br />
          </p>

          <h3>Предупреждение для пользователей</h3>
          <p>
            2.5. Если Пользователь осуществляет доступ к Приложению и/или его
            Услугам через Платформу или социальные сети (к примеру, Facebook,
            Vkontakte, Одноклассники), в таком случае Пользователь автоматически
            считается таким, который принял условия пользовательского соглашения
            такой Платформы или социальной сети.
            <br />
            2.6. Пользователь признает, что его соглашение с провайдером
            мобильной сети (далее <b>"Провайдер"</b>) будет применяться к
            использованию Пользователем данного Приложения. Пользователь также
            признает, что Провайдер может взимать с Пользователя время от
            времени плату за услуги передачи данных при использовании отдельных
            функций Приложения, а также какие-либо иные сборы и платежи,
            возникающие в связи с такой передачей и за которые Пользователь
            обязуется нести ответственность. Если Пользователь не является
            плательщиком счетов Провайдера на смартфоне или другом устройстве,
            используемом для доступа в Приложение, предполагается, что такой
            Пользователь получил разрешение от плательщика счета на
            использование Приложения.
            <br />
            2.7. Пользователь является единственным ответственным лицом за
            проверку и контроль соответствия устанавливаемого Приложения
            техническим особенностям/возможностям смартфона или иного устройства
            и/или других ограничений, которые могут быть применимы к
            Пользователю и/или его смартфону или другому устройству третьими
            лицами, включая Интернет- провайдера.
            <br />
          </p>

          <h2>3. ПОЛЬЗОВАТЕЛИ ПРИЛОЖЕНИЯ</h2>

          <h3>Общие критерии и возраст</h3>
          <p>
            3.1. Для использования Приложения Пользователи должны
            соответствовать следующим критериям (совокупно):
            <br />
            <b>(1)</b> быть не младше 18-лет;
            <br />
            <b>(2)</b> не быть ограниченными в праве доступа к Приложению и
            Услугам на основании решения суда, вступившего в законную силу, или
            в случаях, предусмотренных действующим законодательством или
            условиями данного Соглашения; и<br />
            <b>(3)</b> соблюдать следующие условия/выполнять критерии:
            <br />
            - <br />
            - <br />
            specialist
            <br />
          </p>

          <h3>Создание личного аккаунта</h3>
          <p>
            3.2. Использование данного Приложения возможно как Пользователями,
            которые прошли процедуру бесплатной регистрации и создали свой
            личный аккаунт (кабинет), так и Пользователями, которые отказались
            от такой регистрации.
            <br />
            3.3. Для создания личного аккаунта Пользователю необходимо
            предоставить следующую информацию о себе:
            <br />
            Регистрация для физических лиц:
            <br />
            ФИО, паспортные данные, ПИНФЛ, ИНН, номер телефона,
            <br />
            адрес электронной почты (e-mail)
            <br />
            Регистрация для юридических лиц и индивидуальных предпринимателей:
            <br />
            Полное официальное наименование юридического лица (индивидуального
            предпринимателя), юридический адрес, ИНН, банковские реквизиты,
            номера телефонов / факса, адрес электронной почты (e-mail) и вебсайт
            (при наличии)
            <br />
          </p>

          <h2>4. ИНТЕЛЛЕКТУАЛЬНАЯ СОБСТВЕННОСТЬ</h2>

          <h3>Пользовательское право</h3>
          <p>
            4.1. Пользователь получает неисключительную, без права передачи,
            ТОЛЬКО для личного (некоммерческого) пользования право на Услуги
            (далее <b>"Пользовательское право"</b>). Пользователь берет на себя
            обязательство не использовать Услуги в каких-либо других целях.
            Пользователь получает указанное Пользовательское право ТОЛЬКО при
            условии соблюдения ВСЕХ условий данного Соглашения.
            <br />
            4.2. Пользовательское право прекращает действовать автоматически при
            удалении Приложения со смартфона или другого устройства
            Пользователя. Ничто в тексте настоящего Соглашения не должно быть
            истолковано как право на получение Пользователем какого-либо иного
            права на использование объектов интеллектуальной собственности,
            принадлежащих Компании или находящихся в ее владении, кроме той,
            которая предоставлена выше.
            <br />
          </p>

          <h3>Интеллектуальная собственность компании</h3>
          <p>
            4.3. Компании принадлежат все без исключения права собственности,
            включая имущественные права интеллектуальной собственности, на весь
            Контент приложения, а также ПО приложения. ПО приложения и Контент
            приложения защищены авторским правом в порядке, предусмотренном
            действующим гражданским законодательством Республики Узбекистан, а
            также международными договорами и конвенциями в области защиты
            объектов интеллектуальной собственности.
            <br />
            4.4. ПОЛЬЗОВАТЕЛЯМ ЗАПРЕЩЕНО копировать, воспроизводить,
            модифицировать, компилировать, распространять, отображать в
            какой-либо форме, публиковать, скачивать, передавать, продавать
            (полностью или частично), отчуждать любым возможным способом за
            плату или безвозмездно, передавать в сублицензию, каким-либо
            способом распространять или использовать Контент приложения и ПО
            приложения, кроме случаев, когда такие действия ПРЯМО разрешены
            условиями данного Соглашения или действующим законодательством
            Республики Узбекистан.
            <br />
            4.5. Ничто в тексте данного Соглашения не может быть истолковано как
            передача Пользователю каких-либо исключительных прав на Контент
            приложения (полностью или в отдельной части) и/или ПО приложения.
            <br />
          </p>

          <h2>5. ПОРЯДОК РАБОТЫ С ПРИЛОЖЕНИЕМ</h2>

          <h3>Общие положения</h3>
          <p>
            5.1. Пользователь обязуется соблюдать следующие правила при работе с
            Приложением:
            <br />
            <b>(1)</b> соблюдать все обязательства, взятые на себя Пользователем
            в связи с присоединением к данному Соглашению;
            <br />
            <b>(2)</b> предоставлять достоверные о себе данные для создания
            личного аккаунта (кабинета);
            <br />
            <b>(3)</b> не выдавать себя за какое-либо другое лицо, включая,
            однако не ограничиваясь, не предоставлять какие-либо данные третьих
            лиц (без получения от них прямого, предварительного и осознанного
            согласия) для создания личного аккаунта (кабинета);
            <br />
            <b>(4)</b> информировать Компанию о хищении логинов, паролей или
            каких-либо других ключей доступа Пользователя к личному аккаунту
            (кабинету);
            <br />
            <b>(5)</b> не предоставлять третьим лицам доступ к своему аккаунту
            (кабинету) и/или логины, пароли или другие ключи доступа;
            <br />
            <b>(6)</b> не загружать, не хранить, не публиковать, не
            распространять, не размещать, не рекламировать, не отправлять, не
            предоставлять доступ или иным образом не использовать
            Пользовательский контент, который <b>(а)</b> содержит угрозы,
            дискредитирует, оскорбляет, порочит честь и достоинство или деловую
            репутацию или нарушает неприкосновенность частной жизни других
            Пользователей или третьих лиц; и <b>(б)</b> является спамом,
            буллингом, вульгарным или непристойным, содержит порнографические
            изображения и тексты, сцены сексуального характера, в том числе с
            участием несовершеннолетних, или сцены насилия, включая сексуальное,
            над людьми или животными; и <b>(в)</b> содержит какие-либо формы
            подстрекательства к суициду и/или пропагандирует или способствует
            разжиганию расовой, религиозной, этнической ненависти или вражды,
            пропагандирует фашизм или идеологию расового превосходства, или
            содержит экстремистские материалы; и <b>(г)</b> пропагандирует
            нарушение прав или законных интересов других Пользователей или
            третьих лиц или пропагандирует преступление или содержит
            советы/руководства/инструкции по его совершению; и <b>(д)</b>{" "}
            нарушает иные правила данного Соглашения или является запрещенным на
            основании действующего законодательства;
            <br />
            <b>(7)</b> не совершать какие-либо действия (с использованием
            средств автоматизации или без таких средств), направленные на сбор
            каких-либо персональных данных других Пользователей;
            <b>(8)</b> не предпринимать какие-либо действия и не помогать
            третьим лицам в совершении действий, направленных на подрыв работы
            Приложения и/или Услуг, включая, однако не ограничиваясь, <b>(а)</b>{" "}
            загружать вирусы или вредоносный код;
            <b>(б)</b> совершать действия, которые могут привести к отключению
            Приложения и/или Услуг, к нарушению нормальной работы Приложения или
            его программного обеспечения, или к ухудшению внешнего вида
            Приложения и/или Контента приложения;
            <br />
            <b>(9)</b> не предпринимать какие-либо иные действия, которые
            являются незаконными, мошенническими, дискриминационными или
            вводящими в заблуждение.
            <br />
          </p>

          <h3>Права пользователей на размещенный контент</h3>
          <p>
            5.2. Создаваемый Вами Пользовательский контент является объектом
            интеллектуальной собственности, защищается действующим
            законодательством, в связи с чем Компания не претендует на получение
            и не требует от Вас предоставления ей каких-либо прав собственности
            на Ваш Пользовательский контент. Ничто в тексте настоящего
            Соглашения не должно быть истолковано как лишение Пользователя прав
            на создаваемый им Пользовательский контент или их ограничение.
            <br />
            5.3. Вместе с этим Вы предоставляете Компании неисключительное, не
            требующее отчислений, действующее по всему миру полномочие (далее
            "Полномочие") с правом передачи и выдачи полномочий на хранение,
            использование, распространение, изменение, запуск, копирование,
            публичное исполнение или показ, перевод Вашего Пользовательского
            контента и создание производных работ на его основе.
            <br />
            5.4. Действие выданного Вами таким образом Полномочия автоматически
            прекращается в случае удаления Вашего личного аккаунта (кабинета)
            или в случае удаления Приложения со смартфона или другого
            устройства.
            <br />
            5.5. Компания обязуется предпринять все возможные действия,
            направленные на полное удаление Вашего Пользовательского контента
            сразу после наступления обстоятельств для прекращения Полномочия, за
            исключением следующих случаев когда:
            <b>(1)</b> часть Вашего Пользовательского контента была использована
            другими Пользователями (на основании выданного Вами ранее
            Полномочия, в таком случае Ваш Пользовательский контент будет
            доступным другим Пользователям до тех пор, пока другой Пользователь
            не совершит его удаление); или <b>(2)</b> Пользовательский контент,
            размещенный Вами, является доказательством какого-либо нарушения,
            проступка или преступления в уголовном, административном или
            гражданском производстве, или его последующее хранение требуется на
            основании требований действующего законодательства или полученного
            запроса от компетентного государственного органа; или <b>(3)</b> в
            других случаях, указанных в данном Соглашении.
            <br />
          </p>

          <h3>Требование к Пользовательскому контенту</h3>
          <p>
            5.6. Пользователям запрещается загружать какой-либо Пользовательский
            контент, который может принадлежать третьим лицам или права на
            использование, которого не были в необходимом объеме предоставлены
            такому Пользователю. Настоящим Пользователь берет на себя
            обязанность возместить Компании ВСЕ УБЫТКИ И СУДЕБНЫЕ ИЗДЕРЖКИ,
            КОТОРЫЕ БЫЛИ ЕЙ ПОНЕСЕНЫ В СВЯЗИ С ПРЕДЪЯВЛЕНИЕМ ИСКА ТРЕТЬИМИ
            ЛИЦАМИ О ТОМ, ЧТО ОПУБЛИКОВАННЫЙ ПОЛЬЗОВАТЕЛЬСКИЙ КОНТЕНТ НАРУШАЕТ
            ПРАВА ИНТЕЛЛЕКТУАЛЬНОЙ СОБСТВЕННОСТИ ТАКИХ ТРЕТЬИХ ЛИЦ.
            <br />
            5.7. Пользователю не рекомендуется загружать какой-либо
            Пользовательский контент, который может содержать конфиденциальную
            или иную личную информацию. Компания признает любой Пользовательский
            контент таким, который не содержит конфиденциальной информации
            вовсе, а потому не берет на себя какие-либо обязательства по его
            защите от разглашения или посягательств третьих лиц.
            <br />
            5.8. Компания не проверяет и не может проверить все материалы,
            публикуемые Пользователями в рамках создаваемого ими
            Пользовательского контента, в связи с чем Компания не несет
            ответственности за содержание такого Пользовательского контента, за
            его использование и/или его воздействие на третьих лиц или других
            Пользователей. Управление или владение Приложением не означает, что
            Компания подтверждает, поддерживает, гарантирует, распространяет
            и/или верит в информацию, размещаемую в рамках Пользовательского
            контента.
            <br />
            5.9. Пользователь является ответственным за свою собственную защиту
            и защиту своего устройства от вирусов и прочего вредоносного ПО.
            Компания не берет на себя какую-либо ответственность за вред,
            причиненный в результате использования Приложения, его Услуг и/или
            Пользовательского контента (включая его загрузку).
            <br />
            5.10. Компания имеет право в любой момент проверить Пользовательский
            контент на предмет его соответствия требованиям данного Соглашения
            или действующему законодательству. Вместе с этим ничто в тексте
            данного Соглашения не должно быть истолковано как прямая обязанность
            Компании проводить какую-либо самостоятельную проверку
            Пользовательского контента не иначе, как по запросу других
            Пользователей или третьих лиц.
            <br />
            5.11. Если Вы столкнулись с Пользовательским контентом, который
            нарушает условия данного Соглашения или законные права и интересы
            Пользователей или третьих лиц, Вы можете направить свою жалобу
            следующим образом:
            <br />
            письмом на электронную почту (email) Компании
            <br />
            5.12. Если будет обнаружено, что Пользовательский контент нарушает
            условия данного Соглашения или положения действующего
            законодательства, Компания имеет право по своему собственному
            усмотрению, в любой момент, без необходимости предупреждения
            Пользователя и взятия на себя какой-либо ответственности в будущем
            удалить такой Пользовательский контент вовсе, а в случае повторного
            нарушения - удалить Ваш личный аккаунт (кабинет).
            <br />
            5.13. С целью исполнения требований закона Республики Узбекистан "О
            принципах и гарантиях свободы информации" и закона Республики
            Узбекистан "Об информатизации" Компания имеет право проводить
            внутренний мониторинг Пользовательского контента с целью соблюдения
            Пользователями ограничений, изложенных в законе Республики
            Узбекистан "О принципах и гарантиях свободы информации" и законе
            Республики Узбекистан "Об информатизации".
            <br />
          </p>

          <h3>Отзывы о работе приложения</h3>
          <p>
            5.14. Каждый Пользователь время от времени имеет право (но не
            обязанность) оставлять или направлять свои идеи, отзывы, предложения
            или проекты, направленные на улучшение работы Приложения или
            качества предоставляемых Услуг. Такие отзывы могут быть направлены
            Пользователем следующим способом:
            <br />
            письмом на электронную почту (email) Компании
            <br />
            5.15. В случае направления такой идеи, отзыва, предложения или
            проекта Пользователь автоматически предоставляет нам
            неисключительную, не требующую отчислений, действующее по всему миру
            полномочие с правом передачи и выдачи разрешений на хранение,
            использование, распространение, изменение, запуск, копирование,
            публичное исполнение или показ, перевод Ваших идей, отзывов,
            предложений или проектов, а также создание производных работ на их
            основе.
            <br />
            5.16. Любая такая информация, предоставляемая Компании,
            автоматически признается не конфиденциальной.
            <br />
          </p>

          <h2>6. РАЗМЕЩЕНИЕ РЕКЛАМЫ В ПРИЛОЖЕНИИ</h2>

          <h3>Размещение рекламы компанией</h3>
          <p>
            6.1. Компания имеет право время от времени размещать какие-либо
            рекламные или маркетинговые материалы.
            <br />
          </p>

          <h3>Размещение рекламы третьими лицами</h3>
          <p>
            6.2. Контент приложения может содержать ссылки на веб-сайты третьих
            лиц и/или рекламные или маркетинговые материалы о товарах/услугах,
            предоставляемых такими третьими лицами (далее{" "}
            <b>"Реклама третьих лиц"</b>). КОМПАНИЯ НЕ БЕРЕТ НА СЕБЯ КАКУЮ-ЛИБО
            ОТВЕТСТВЕННОСТЬ <b>(1)</b> ЗА СОДЕРЖАНИЕ РЕКЛАМЫ ТРЕТЬИХ ЛИЦ, А
            ТАКЖЕ ЗА НАЛИЧИЕ, КАЧЕСТВО И БЕЗОПАСНОСТЬ ПРОДВИГАЕМЫХ ТОВАРОВ/УСЛУГ
            В ТАКОЙ РЕКЛАМЕ; и <b>(2)</b> ЗА КАКИЕ-ЛИБО УБЫТКИ, ПОТЕРИ ИЛИ
            УЩЕРБ, ПОНЕСЕННЫЕ ИЛИ ПРИЧИНЕННЫЕ ПОЛЬЗОВАТЕЛЮ В РЕЗУЛЬТАТЕ
            ПРОЧТЕНИЯ ИМ ТАКОЙ РЕКЛАМЫ, ИСПОЛЬЗОВАНИЯ ИМ ПРОДВИГАЕМЫХ В РЕКЛАМЕ
            ТРЕТЬИХ ЛИЦ ТОВАРОВ/УСЛУГ.
            <br />
            6.3. В случае перехода на другой сайт через размещенную Рекламу
            третьих лиц, Компания не может гарантировать, что такой веб-сайт
            является безопасным для Пользователя и/или его компьютера. Ни что в
            тексте данного Соглашения не должно быть истолковано как заверение,
            поощрение, рекомендация или побуждение Пользователя воспользоваться
            Рекламой третьих лиц, посетить какие-либо сайты третьих лиц, а также
            попробовать, приобрести, воспользоваться какими-либо
            товарами/услугами третьих лиц.
            <br />
            6.4. Вопросы, связанные с защитой персональных данных Пользователей
            при использовании ими Рекламы третьих лиц, регулируются Политикой
            конфиденциальности приложения.
            <br />
          </p>

          <h2>7. ПРОВЕДЕНИЕ ОПЛАТЫ ЧЕРЕЗ ПРИЛОЖЕНИЕ</h2>

          <h3>Общие положения</h3>
          <p>
            7.1. Пользователи имеют возможность проводить оплату за
            товары/услуги через Приложение. Продавцом таких товаров/услуг
            выступают третьи лица (партнеры, продавцы, дистрибьюторы) (далее
            "Поставщики"), которые с разрешения Компании используют Приложение
            для размещения продукции.
            <br />
            7.2. ПРЕДУПРЕЖДЕНИЕ ПРИ ПОКУПКЕ ТОВАРОВ/УСЛУГ ОТ ПОСТАВЩИКОВ.
            Ответственность за исполнение договора купли-продажи, заключенного
            между Пользователем и Поставщиком через Приложение на основании
            предоставленной Компанией информации о товаре/услуге или Поставщике,
            а также за соблюдение прав потребителей, нарушенных в результате
            передачи потребителю товара/услуги ненадлежащего качества и обмена
            непродовольственного товара надлежащего качества на аналогичный
            товар, несет Поставщик.
            <br />
            7.3. Компания не хранит какие-либо данные о Вашей дебетовой или
            кредитной карте, используемой для оплаты.
            <br />
            7.4. КОМПАНИЯ ПРЕДОСТАВЛЯЕТ ЗАКАЗАННЫЙ ТОВАР/УСЛУГУ ТОЛЬКО ПОСЛЕ
            ПОЛУЧЕНИЯ ОПЛАТЫ В ПОЛНОМ ОБЪЕМЕ.
            <br />
            7.5. Если Компания не может предоставить Вам заказанный и оплаченный
            товар/услугу, Компания обязуется незамедлительно провести возврат
            Вам всей уплаченной ранее суммы в счет погашения стоимости
            заказанного товара/услуги (за исключением банковских комиссий и
            издержек, которые могли быть начислены Вашим обслуживающим банком
            при оплате товаров/услуг через Приложение).
            <br />
            7.6. Компания не гарантирует наличие того или иного товара/услуги.
            <br />
          </p>

          <h3>Способы и сроки доставки</h3>
          <p>
            7.7. Компания предоставляет следующие способы доставки заказанных
            товаров/услуг:
            <br />
            оказание услуг специалистом
            <br />
            7.8. Пользователь имеет право предъявить Компании требование о
            возврате уплаченных им ранее денежных средств за товар/услугу, если
            такой товар/услуга не были получены Пользователем в согласованный
            срок. В таком случае Компания обязуется в течение 10 (десяти)
            календарных дней осуществить возврат такой суммы в полном объеме.
            <br />
            7.9. Компания не берет на себя какую-либо ответственность за ошибку
            в доставке, которая произошла по вине Пользователя, который, к
            примеру, не указал верные данные получателя.
            <br />
          </p>

          <h3>Порядок оплаты</h3>
          <p>
            7.10. Стоимость товара/услуги в Приложении указывается в узбекских
            сумах. Оплата за товар/услугу также должна быть проведена в
            узбекских сумах.
            <br />
            7.11. Если у Вас нету счета в указанной валюте, в таком случае Вы
            можете воспользоваться Вашей дебетовой или кредитной картой, а
            обслуживающий банк (держатель дебетовой или кредитной карты)
            проведет соответствующую конвертацию по обменному курсу согласно его
            внутрибанковским правилам. Компания не отвечает за обменный курс,
            используемый Вашим обслуживающим банком при такой конвертации.
            <br />
            7.12. Пользователь может провести оплату одним из следующих
            способов:
            <br />
            банковской картой или любым другим способом электронного платежа
            <br />
            7.13. Обращаем внимание, что Компания может в любой момент отказать
            в приеме того или иного способа платежа без какого-либо объяснения
            или уведомления Пользователей.
            <br />
            7.14. Товар/услуга считается оплаченным Пользователем в полном
            объеме с момента подтверждения исполнения такого платежа банковским
            учреждением, обслуживающим дебетовую или кредитную карту
            Пользователя, которая была им использована для оплаты через
            Приложение.
            <br />
            7.15. Обращаем внимание, что Ваш обслуживающий банк может проводить
            по своему собственному усмотрению дополнительные проверки
            совершенной транзакции, что в последствие может привести к задержке
            оплаты заказанного Вами ранее товара/услуги.
            <br />
            7.16. Компания не берет на себя какую-либо ответственность за
            убытки, ущерб, упущенную выгоду, потерю деловой репутации, возникшие
            у Пользователя из-за задержки в оплате, что в свою очередь могло
            привести к задержке с нашей стороны в предоставлении Вам
            определенного товара/услуги.
            <br />
          </p>

          <h3>Порядок обмена и возврата товара</h3>
          <p>
            7.17. Пользователь имеет право на обмен или возврат ранее
            приобретенного товара. Обмену и возврату не подлежат только те
            товары, которые указаны в Постановлении Правительства Российской
            Федерации от 31 декабря 2020 г. № 2463 "Об утверждении Правил
            продажи товаров по договору розничной купли-продажи, перечня товаров
            длительного пользования, на которые не распространяется требование
            потребителя о безвозмездном предоставлении ему товара, обладающего
            этими же основными потребительскими свойствами, на период ремонта
            или замены такого товара, и перечня непродовольственных товаров
            надлежащего качества, не подлежащих обмену, а также о внесении
            изменений в некоторые акты Правительства Российской Федерации". Во
            всех остальных случаях Пользователь имеет право обратиться к
            Компании с требованием об обмене или возврате товара в порядке,
            предусмотренном действующим законодательством о защите прав
            потребителей, а также условиями данного Соглашения.
            <br />
            7.18. Пользователь имеет право возвратить обратно или обменять товар
            надлежащего качества без указания причины в течение 14
            (четырнадцати) календарных дней с момента совершения его покупки. В
            случае возврата товара надлежащего качества Пользователю
            возвращаются в полном объеме все суммы, уплаченные им в счет
            погашения стоимости такого товара. Для обмена и возврата
            приобретенного товара Пользователю необходимо выполнить следующие
            действия:
            <br />
            направить запрос на email
            <br />
          </p>

          <h3>Качество приобретаемого товара</h3>
          <p>
            7.19. Сразу после получения товара на руки Пользователь обязан
            проверить его на наличие возможных дефектов или недостатков, а также
            на предмет соответствия характеристик товара его описанию.
            <br />
            7.20. Пользователь имеет право предъявить Компании требование о
            возврате уплаченных им ранее денежных средств за товар/услугу, если
            такой товар/услуга не соответствуют заявленным условиям качества
            товара или его описанию. В таком случае Компания обязуется в течение
            10 (десяти) календарных дней осуществить возврат такой суммы в
            полном объеме.
            <br />
            7.21. Пользователь имеет право предъявить свои претензии к качеству
            приобретенного товара к Поставщикам в течение установленного
            гарантийного срока/срока годности товара или, если такой срок не
            установлен, в течение 2 (двух) лет от момента совершения покупки
            такого товара.
            <br />
          </p>

          <h3>Акции на товары/услуг</h3>
          <p>
            7.22. Время от времени Компания может размещать какие-либо акционные
            предложения на товары/услуги. Периодичность размещения таких
            предложений, а также их условия определяются исключительно
            Компанией.
            <br />
            7.23. Пользователь осведомлен, что количество акционных предложений
            на товары/услуги ограничено.
            <br />
            7.24. Компания не гарантирует и не обещает Пользователям, что{" "}
            <b>(1)</b> приобретение каких-либо акционных товаров/услуг является
            каким-либо образом выгодным для Пользователя и/или третьих лиц; и{" "}
            <b>(2)</b> стоимость акционных товаров/услуг должна быть в
            обязательном порядке ниже их обычной стоимости, указанной в
            Приложении, или на других веб-сайтах третьих лиц.
            <br />
          </p>

          <h2>8. СОВЕРШЕНИЕ IN-APP ПОКУПОК</h2>

          <p>
            8.1. Совершение каких-либо In-app покупок внутри Приложения не
            предусмотрено.
          </p>

          <h2>9. ДОСТУП К ПРИЛОЖЕНИЮ</h2>

          <h3>Общие положения</h3>
          <p>
            9.1. Компания оставляет за собой право изменить или модифицировать
            Контент приложения в любой момент без указания причины, по своему
            собственному усмотрению и без необходимости уведомления об этом
            Пользователя. Компания также оставляет за собой право
            модифицировать, прервать или прекратить работу части или всего
            Приложения в любой момент без какого-либо дополнительного
            уведомления. В связи с изложенным, Компания не берет на себя
            какую-либо ответственность перед Пользователями или третьими лицами
            за любые произведенные изменения, модификации, удаления,
            упразднения, прекращения или перерывы в работе Приложения.
            <br />
            9.2. Компания не гарантирует, что Приложение и Услуги будут
            доступными Пользователю постоянно. Время от времени Компания может
            сталкиваться с проблемами в работе оборудования, ПО приложения или
            другими неполадками, в связи с чем Компании может потребоваться
            время на изучение и устранение таких проблем. Такое устранение
            ошибок может привести к сбоям, задержкам или ошибкам в работе
            Приложения. Компания оставляет за собой право изменять,
            пересматривать, обновлять, приостанавливать, прекращать или иным
            образом изменять Приложение в любое время или по любой причине без
            предварительного уведомления. Пользователь соглашается с тем, что
            Компания не несет ответственности за какие-либо убытки, ущерб или
            неудобства, вызванные неспособностью Пользователя получить доступ
            или использовать Приложение во время простоя или прекращения работы
            Приложения. Ничто в условиях данного Соглашения не будет истолковано
            как обязывающее Нас поддерживать работу Приложения без остановок и
            сбоев.
            <br />
          </p>

          <h3>Предоставление обновлений</h3>
          <p>
            9.3. Компания время от времени может предоставлять Обновления и
            требовать их инсталляции на смартфон или другое устройство
            Пользователя. В данном случае Пользователь является единственным
            ответственным лицом за установление Обновлений и несет полную
            ответственность за какие-либо убытки, потери, ущерб или упущенную
            выгоду, причиненную Пользователю несвоевременным установлением
            Обновлений или неустановлением их вовсе, несовместимостью
            установленных Обновлений и смартфона/другого устройства. Компания не
            предоставляет какую-либо техническую поддержку или
            интернет-соединение Пользователю для возможности получения доступа к
            Услугам и/или их Обновлениям.
            <br />
          </p>

          <h3>Удаление аккаунта</h3>
          <p>
            9.4. Пользователь имеет право в любой момент прекратить
            использование Приложения посредством удаления его со своего
            смартфона или другого устройства.
            <br />
            9.5. В случае <b>(1)</b> нарушения Пользователем условий настоящего
            Соглашения или когда у Компании есть достаточные основания полагать,
            что такие нарушения были допущены; и/или <b>(2)</b> нарушения прав
            интеллектуальной собственности Компании, других Пользователей или
            третьих лиц; и/или <b>(3)</b> совершения действий, которые являются
            противозаконными, нарушают права и интересы Компании, других
            Пользователей или третьих лиц или подрывают работу Приложения или
            возможность использования Приложения другими Пользователями; и/или{" "}
            <b>(4)</b> Пользователь использует Услуги или Приложение таким
            образом, что это может повлечь юридическую ответственность для
            Компании в будущем; и/или <b>(5)</b> если этого требует действующее
            законодательство или компетентный государственный орган, Компания
            имеет право без предварительного уведомления в любой момент
            прекратить (остановить) доступ Пользователя к Приложению и Услугам
            посредством удаления его аккаунта.
            <br />
            При наступлении обстоятельств, изложенных в предыдущем абзаце,
            Пользователю запрещено создавать какие-либо другие аккаунты в
            Приложении в будущем.
            <br />
            9.6. Компания имеет право также удалить аккаунт Пользователя по
            причине его неактивности в течение 365 календарных дней подряд.
            <br />
            9.7. Во всех случаях удаления аккаунта Пользователя или удаления
            Приложения со смартфона или другого устройства Пользователя все
            данные и информация, размещенные Пользователем в аккаунте и/или
            связанные с ним, будут безвозвратно удалены. Компания не берет на
            себя какую-либо ответственность за удаление таких данных и
            информации, а также за какой-либо вред, ущерб, убытки или упущенную
            выгоду, причиненную Пользователю таким удалением и/или отсутствием
            доступа к Услугам в целом.
            <br />
          </p>

          <h3>10. ЗАДАТЬ ВОПРОС</h3>
          <p>
            10.1. Если у Вас есть вопросы касательно условий настоящего
            Соглашения или порядка/способа их исполнения, Вы можете адресовать
            нам свой вопрос следующим способом:
            <br />
            отправить письмо на электронную почту (email) Компании
            <br />
            10.2. Сотрудники и представители Компании обязуются предпринять все
            возможные усилия для дачи ответа на Ваш запрос в течение разумного
            периода времени.
            <br />
          </p>

          <h3>11. ОТВЕТСТВЕННОСТЬ</h3>
          <p>
            11.1. НИ ПРИ КАКИХ ОБСТОЯТЕЛЬСТВАХ КОМПАНИЯ ИЛИ ЕЕ ПРЕДСТАВИТЕЛИ НЕ
            НЕСУТ ОТВЕТСТВЕННОСТЬ ПЕРЕД ПОЛЬЗОВАТЕЛЕМ ИЛИ ПЕРЕД ЛЮБЫМИ ТРЕТЬИМИ
            ЛИЦАМИ:
            <br />
            (1) ЗА ЛЮБОЙ КОСВЕННЫЙ, СЛУЧАЙНЫЙ, НЕУМЫШЛЕННЫЙ УЩЕРБ, ВКЛЮЧАЯ
            УПУЩЕННУЮ ВЫГОДУ ИЛИ ПОТЕРЯННЫЕ ДАННЫЕ, ВРЕД ЧЕСТИ, ДОСТОИНСТВУ ИЛИ
            ДЕЛОВОЙ РЕПУТАЦИИ, ВЫЗВАННЫЙ ИСПОЛЬЗОВАНИЕМ ПРИЛОЖЕНИЯ, УСЛУГ ИЛИ
            ИНЫХ МАТЕРИАЛОВ, К КОТОРЫМ ПОЛЬЗОВАТЕЛЬ ИЛИ ИНЫЕ ЛИЦА ПОЛУЧИЛИ
            ДОСТУП С ПОМОЩЬЮ ПРИЛОЖЕНИЯ, ДАЖЕ ЕСЛИ КОМПАНИЯ ПРЕДУПРЕЖДАЛА ИЛИ
            УКАЗЫВАЛА НА ВОЗМОЖНОСТЬ ТАКОГО ВРЕДА; и<br />
            (2) ЗА ДЕЙСТВИЯ ДРУГИХ ПОЛЬЗОВАТЕЛЕЙ, ЗА РАЗМЕЩАЕМЫЙ ПОЛЬЗОВАТЕЛЯМИ
            ПОЛЬЗОВАТЕЛЬСКИЙ КОНТЕНТ, ЗА ТОВАРЫ/УСЛУГИ, ПРЕДОСТАВЛЯЕМЫЕ ТРЕТЬИМИ
            ЛИЦАМИ ИЛИ ДРУГИМИ ПОЛЬЗОВАТЕЛЯМИ (ДАЖЕ ЕСЛИ ДОСТУП К НИМ БЫЛ
            ПРЕДОСТАВЛЕН ЧЕРЕЗ НАШЕ ПРИЛОЖЕНИЕ), К КОТОРЫМ ПОЛЬЗОВАТЕЛЬ ПОЛУЧИЛ
            ДОСТУП; и<br />
            (3) в случаях, прямо предусмотренных условиями настоящего Соглашения
            или нормой действующего законодательства.
            <br />
            11.2. Наша ответственность за все что угодно, связанное с
            использованием Приложения и/или Услуг, ограничивается настолько
            сильно, насколько это допускается действующим законодательством.
            <br />
          </p>

          <h3>12. ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ</h3>
          <p>
            12.1. В случае возникновения любых споров или разногласий, связанных
            с исполнением настоящего Соглашения, Пользователь и Компания
            приложат все усилия для их разрешения путем проведения переговоров
            между ними. В случае, если споры не будут разрешены путем
            переговоров, споры подлежат разрешению в порядке, установленном
            действующим законодательством Республики Узбекистан.
            <br />
          </p>

          <h3>13. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h3>
          <p>
            13.1. Мы можем время от времени пересматривать, дополнять или
            изменять условия данного Соглашения. Такие изменения, как правило,
            не являются ретроспективными.
            <br />
            КОМПАНИЯ НЕ БЕРЕТ НА СЕБЯ КАКУЮ-ЛИБО ОБЯЗАННОСТЬ ПО УВЕДОМЛЕНИЮ
            ПОЛЬЗОВАТЕЛЕЙ О ПРЕДСТОЯЩИХ ИЛИ СОСТОЯВШИХСЯ ИЗМЕНЕНИЯХ ТЕКСТА
            СОГЛАШЕНИЯ. Посредством присоединения к условиям данного Соглашения
            Пользователь также берет на себя обязательство периодически
            просматривать условия данного Соглашения на предмет их изменения или
            дополнения. Если после проведенных изменений или дополнений в тексте
            Соглашения Пользователь продолжает пользоваться Приложением, это
            значит, что он ознакомлен с изменениями или дополнениями и принял их
            в полном объеме без каких-либо возражений.
            <br />
            13.2. Если иное прямо не указано в положениях настоящего Соглашения
            или прямо не вытекает из норм действующего законодательства, к
            условиям настоящего Соглашения применяется материальное право
            Республики Узбекистан.
            <br />
            13.3. Неотъемлемой частью настоящего Соглашения является Политика
            конфиденциальности Приложения.
            <br />
            13.4. Если одно или несколько условий данного Соглашения утратило
            свою юридическую силу или признано недействительным согласно
            действующему законодательству, остальные условия Соглашения не
            теряют своей силы и продолжают действовать так, словно признанного
            недействительным или утратившим юридическую силу условия не
            существовало вовсе.
            <br />
            13.5. Доступ к Приложению и его Услугам предоставляется Пользователю
            "как есть", Мы не обещаем, не гарантируем, не подразумеваем, что
            Услуги и Приложение могут подходить или не подходить Вашим
            потребностям, целям, ожиданиям, и в связи с чем не гарантируем
            какого-либо специфического результата или последствия в результате
            использования Вами Приложения и его Услуг.
            <br />
          </p>
        </ContentWrapper>
      </div>
    </div>
  );
};

export default TermsOfUse;

const NavWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (hover: hover) and (pointer: fine) {
    a:hover::before {
      left: 0;
      right: auto;
      width: 100%;
    }
  }
`;

const ContentWrapper = styled.div`
  @media only screen and (min-width: 1000px) {
    padding: 4% 20% 0px 20%;
  }
  @media only screen and (max-width: 500px) {
    padding: 4% 0% 0px 0%;
  }
`;

const Logo = styled.img`
  width: 56px;
  height: 56px;
`;
